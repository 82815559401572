// LastEpisode.js

import React from 'react';
import './LastEpisode.css';
import download from '../../assets/download.png';
import external from '../../assets/external.png';
import Spin from '../Spin/Spin';

const NowPlaying = React.lazy(() => import('../NowPlaying/NowPlaying'));

function MostRecent() {

    /* const audioSrc = "http://35.7.34.15:5150/(0) Last Episode/LastEpisode.mp3" */
    const audioSrc = "https://djdoubletwo.duckdns.org:5150/(0) Last Episode/LastEpisode.mp3"
    const driveLink = "https://drive.google.com/uc?export=download&id=1Py13hdai4e6DV6irap91GaaA3EyqyABY"
    const epName = "11/15/24 Def Squad"
    const spinLink = "https://spinitron.com/WCBN/pl/19801745/Live-from-the-Dungeon"
    const lastDescription = "This week we hear tracks from members of Erick Sermon's Def Squad. Enjoy."
    const spins = [
        { num: "1", artist: "Erick Sermon", track: "Welcome", album: "Double or Nothing", label: "RAL (Rush Associated Label)", year: "1995", cover: require("../../assets/Spins/spin1.jpg") },
        { num: "2", artist: "Busta Rhymes", track: "Flipmode Squad Meets Def Squad (feat. Jamal, Redman, Keith Murray, Rampage the Last Boy Scout, Lord Have Mercy)", album: "The Coming", label: "Rhino/Elektra", year: "1996", cover: require("../../assets/Spins/spin2.jpg") },
        { num: "3", artist: "Erick Sermon & Redman", track: "Funkorama (feat. Redman)", album: "Insomnia: The Erick Sermon Compilation Album", label: "Def Squad", year: "1996", cover: require("../../assets/Spins/spin3.jpg") },
        { num: "4", artist: "Keith Murray", track: "The Most Beautifullest Thing In This World", album: "The Most Beautifullest Thing In This World", label: "Jive", year: "1994", cover: require("../../assets/Spins/spin4.jpg") },
        { num: "5", artist: "Jamal", track: "Fades Em All", album: "Last Chance, No Breaks", label: "Arista/Legacy", year: "1995", cover: require("../../assets/Spins/spin5.jpg") },
        { num: "6", artist: "Erick Sermon", track: "Bomdigi", album: "Double or Nothing", label: "RAL (Rush Associated Label)", year: "1995", cover: require("../../assets/Spins/spin6.jpg") },
        { num: "7", artist: "Redman", track: "Tonight's Da Night", album: "Whut? Thee Album", label: "RAL (Rush Associated Label)", year: "1992", cover: require("../../assets/Spins/spin7.jpg") },
        { num: "8", artist: "Keith Murray", track: "Escapism", album: "The Most Beautifullest Thing In This World", label: "Jive", year: "1994", cover: require("../../assets/Spins/spin8.jpg") },
        { num: "9", artist: "Jamal", track: "Keep It Live", album: "Last Chance, No Breaks", label: "Arista/Legacy", year: "1995", cover: require("../../assets/Spins/spin9.jpg") },
        { num: "10", artist: "Redman", track: "Whateva Man (feat. Erick Sermon)", album: "Muddy Waters", label: "RAL (Rush Associated Label)", year: "1996", cover: require("../../assets/Spins/spin10.jpg") },
        { num: "11", artist: "Jamal", track: "Genetic for Terror (feat. Erick Sermon, Keith Murray, L.O.D. & Redman)", album: "Last Chance, No Breaks", label: "Arista/Legacy", year: "1995", cover: require("../../assets/Spins/spin11.jpg") },
        { num: "12", artist: "Erick Sermon", track: "Do Your Thing", album: "Double or Nothing", label: "RAL (Rush Associated Label)", year: "1995", cover: require("../../assets/Spins/spin12.jpg") },
        { num: "13", artist: "Keith Murray", track: "Bom Bom Zee (feat. Paul Hightower of 309 & Hurricane Gee)", album: "The Most Beautifullest Thing In This World", label: "Jive", year: "1994", cover: require("../../assets/Spins/spin13.jpg") },
        { num: "14", artist: "Def Squad", track: "Rapper's Delight", album: "Single", label: "Priority", year: "1997", cover: require("../../assets/Spins/spin14.jpg") },
    ];

    return (
        <div className='lastEpisode'>
            <h1>Last Episode<hr className='headerHR'/></h1>
            <div className='recentContent'>
                <h2 className='lastLink'><a href={driveLink}>{epName}: <img src={ download } className='downloadIcon' alt='Download Icon' width='25px'/></a></h2>
                <div className='lastDescription'>{lastDescription}</div>
                <NowPlaying name={epName} linkSrc={audioSrc} />
                <hr/>
                <div className='tracks'>
                    <h2 className='lastLink'><a href={spinLink} target='_blank' rel='noreferrer'>Playlist:  <img src={ external } className='externalIcon' alt='External Icon' width='20px'/></a></h2>
                    {spins.map((trackInfo, index) => (
                        <Spin key={index} {...trackInfo} />
                    ))}
                </div>
            </div>
            <hr/>
        </div>
    );
};

export default MostRecent;